exports.components = {
  "component---src-pages-24-7-saas-support-system-tsx": () => import("./../../../src/pages/24-7-saas-support-system.tsx" /* webpackChunkName: "component---src-pages-24-7-saas-support-system-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-ai-llm-tsx": () => import("./../../../src/pages/ai-llm.tsx" /* webpackChunkName: "component---src-pages-ai-llm-tsx" */),
  "component---src-pages-application-modernization-services-tsx": () => import("./../../../src/pages/application-modernization-services.tsx" /* webpackChunkName: "component---src-pages-application-modernization-services-tsx" */),
  "component---src-pages-blog-post-tsx": () => import("./../../../src/pages/blog-post.tsx" /* webpackChunkName: "component---src-pages-blog-post-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-booking-management-solutions-tsx": () => import("./../../../src/pages/booking-management-solutions.tsx" /* webpackChunkName: "component---src-pages-booking-management-solutions-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-casestudyimages-tsx": () => import("./../../../src/pages/casestudyimages.tsx" /* webpackChunkName: "component---src-pages-casestudyimages-tsx" */),
  "component---src-pages-cloud-service-providers-end-to-end-management-tsx": () => import("./../../../src/pages/cloud-service-providers-end-to-end-management.tsx" /* webpackChunkName: "component---src-pages-cloud-service-providers-end-to-end-management-tsx" */),
  "component---src-pages-cohuman-solution-tsx": () => import("./../../../src/pages/cohuman-solution.tsx" /* webpackChunkName: "component---src-pages-cohuman-solution-tsx" */),
  "component---src-pages-connected-vehicle-solutions-tsx": () => import("./../../../src/pages/connected-vehicle-solutions.tsx" /* webpackChunkName: "component---src-pages-connected-vehicle-solutions-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-custom-integration-technology-solutions-tsx": () => import("./../../../src/pages/custom-integration-technology-solutions.tsx" /* webpackChunkName: "component---src-pages-custom-integration-technology-solutions-tsx" */),
  "component---src-pages-customer-engagement-solutions-tsx": () => import("./../../../src/pages/customer-engagement-solutions.tsx" /* webpackChunkName: "component---src-pages-customer-engagement-solutions-tsx" */),
  "component---src-pages-cyber-security-consultant-services-tsx": () => import("./../../../src/pages/cyber-security-consultant-services.tsx" /* webpackChunkName: "component---src-pages-cyber-security-consultant-services-tsx" */),
  "component---src-pages-dealer-management-system-software-solutions-tsx": () => import("./../../../src/pages/dealer-management-system-software-solutions.tsx" /* webpackChunkName: "component---src-pages-dealer-management-system-software-solutions-tsx" */),
  "component---src-pages-dealership-management-system-tsx": () => import("./../../../src/pages/dealership-management-system.tsx" /* webpackChunkName: "component---src-pages-dealership-management-system-tsx" */),
  "component---src-pages-devsecops-tsx": () => import("./../../../src/pages/devsecops.tsx" /* webpackChunkName: "component---src-pages-devsecops-tsx" */),
  "component---src-pages-digital-transformation-in-travel-hospitality-tsx": () => import("./../../../src/pages/digital-transformation-in-travel-hospitality.tsx" /* webpackChunkName: "component---src-pages-digital-transformation-in-travel-hospitality-tsx" */),
  "component---src-pages-ecommerce-development-services-tsx": () => import("./../../../src/pages/ecommerce-development-services.tsx" /* webpackChunkName: "component---src-pages-ecommerce-development-services-tsx" */),
  "component---src-pages-emerging-tech-skills-tsx": () => import("./../../../src/pages/emerging-tech-skills.tsx" /* webpackChunkName: "component---src-pages-emerging-tech-skills-tsx" */),
  "component---src-pages-enterprise-modernization-services-tsx": () => import("./../../../src/pages/enterprise-modernization-services.tsx" /* webpackChunkName: "component---src-pages-enterprise-modernization-services-tsx" */),
  "component---src-pages-high-tech-cloud-security-tsx": () => import("./../../../src/pages/high-tech-cloud-security.tsx" /* webpackChunkName: "component---src-pages-high-tech-cloud-security-tsx" */),
  "component---src-pages-homepage-tsx": () => import("./../../../src/pages/homepage.tsx" /* webpackChunkName: "component---src-pages-homepage-tsx" */),
  "component---src-pages-hospitality-software-solutions-tsx": () => import("./../../../src/pages/hospitality-software-solutions.tsx" /* webpackChunkName: "component---src-pages-hospitality-software-solutions-tsx" */),
  "component---src-pages-image-tsx": () => import("./../../../src/pages/image.tsx" /* webpackChunkName: "component---src-pages-image-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-edtech-tsx": () => import("./../../../src/pages/industries-edtech.tsx" /* webpackChunkName: "component---src-pages-industries-edtech-tsx" */),
  "component---src-pages-industries-fintech-tsx": () => import("./../../../src/pages/industries-fintech.tsx" /* webpackChunkName: "component---src-pages-industries-fintech-tsx" */),
  "component---src-pages-industries-healthtech-tsx": () => import("./../../../src/pages/industries-healthtech.tsx" /* webpackChunkName: "component---src-pages-industries-healthtech-tsx" */),
  "component---src-pages-industries-hitech-tsx": () => import("./../../../src/pages/industries-hitech.tsx" /* webpackChunkName: "component---src-pages-industries-hitech-tsx" */),
  "component---src-pages-industries-martech-tsx": () => import("./../../../src/pages/industries-martech.tsx" /* webpackChunkName: "component---src-pages-industries-martech-tsx" */),
  "component---src-pages-integrated-solutions-for-retailers-tsx": () => import("./../../../src/pages/integrated-solutions-for-retailers.tsx" /* webpackChunkName: "component---src-pages-integrated-solutions-for-retailers-tsx" */),
  "component---src-pages-it-staff-augmentation-services-saas-tsx": () => import("./../../../src/pages/it-staff-augmentation-services-saas.tsx" /* webpackChunkName: "component---src-pages-it-staff-augmentation-services-saas-tsx" */),
  "component---src-pages-manufacturing-solutions-structure-of-the-industry-tsx": () => import("./../../../src/pages/manufacturing-solutions-structure-of-the-Industry.tsx" /* webpackChunkName: "component---src-pages-manufacturing-solutions-structure-of-the-industry-tsx" */),
  "component---src-pages-mobility-as-a-service-tsx": () => import("./../../../src/pages/mobility-as-a-service.tsx" /* webpackChunkName: "component---src-pages-mobility-as-a-service-tsx" */),
  "component---src-pages-new-case-study-tsx": () => import("./../../../src/pages/new-case-study.tsx" /* webpackChunkName: "component---src-pages-new-case-study-tsx" */),
  "component---src-pages-omnichannel-retail-ecommerce-solutions-tsx": () => import("./../../../src/pages/omnichannel-retail-ecommerce-solutions.tsx" /* webpackChunkName: "component---src-pages-omnichannel-retail-ecommerce-solutions-tsx" */),
  "component---src-pages-point-of-sale-systems-tsx": () => import("./../../../src/pages/point-of-sale-systems.tsx" /* webpackChunkName: "component---src-pages-point-of-sale-systems-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-quality-assurance-solutions-tsx": () => import("./../../../src/pages/quality-assurance-solutions.tsx" /* webpackChunkName: "component---src-pages-quality-assurance-solutions-tsx" */),
  "component---src-pages-restaurant-management-systems-tsx": () => import("./../../../src/pages/restaurant-management-systems.tsx" /* webpackChunkName: "component---src-pages-restaurant-management-systems-tsx" */),
  "component---src-pages-retail-inventory-management-solutions-tsx": () => import("./../../../src/pages/retail-inventory-management-solutions.tsx" /* webpackChunkName: "component---src-pages-retail-inventory-management-solutions-tsx" */),
  "component---src-pages-retail-software-solutions-tsx": () => import("./../../../src/pages/retail-software-solutions.tsx" /* webpackChunkName: "component---src-pages-retail-software-solutions-tsx" */),
  "component---src-pages-saas-application-tsx": () => import("./../../../src/pages/saas-application.tsx" /* webpackChunkName: "component---src-pages-saas-application-tsx" */),
  "component---src-pages-saas-bundle-offerings-tsx": () => import("./../../../src/pages/saas-bundle-offerings.tsx" /* webpackChunkName: "component---src-pages-saas-bundle-offerings-tsx" */),
  "component---src-pages-saas-implementation-tsx": () => import("./../../../src/pages/saas-implementation.tsx" /* webpackChunkName: "component---src-pages-saas-implementation-tsx" */),
  "component---src-pages-saas-integration-platform-tsx": () => import("./../../../src/pages/saas-integration-platform.tsx" /* webpackChunkName: "component---src-pages-saas-integration-platform-tsx" */),
  "component---src-pages-services-rpa-tsx": () => import("./../../../src/pages/services-rpa.tsx" /* webpackChunkName: "component---src-pages-services-rpa-tsx" */),
  "component---src-pages-soc-2-compliance-saas-solutions-tsx": () => import("./../../../src/pages/soc-2-compliance-saas-solutions.tsx" /* webpackChunkName: "component---src-pages-soc-2-compliance-saas-solutions-tsx" */),
  "component---src-pages-solution-api-dev-integration-tsx": () => import("./../../../src/pages/solution-api-dev-integration.tsx" /* webpackChunkName: "component---src-pages-solution-api-dev-integration-tsx" */),
  "component---src-pages-solution-elevate-your-fleet-management-with-advance-solutions-tsx": () => import("./../../../src/pages/solution-elevate-your-fleet-management-with-advance-solutions.tsx" /* webpackChunkName: "component---src-pages-solution-elevate-your-fleet-management-with-advance-solutions-tsx" */),
  "component---src-pages-solution-mobile-application-tsx": () => import("./../../../src/pages/solution-mobile-application.tsx" /* webpackChunkName: "component---src-pages-solution-mobile-application-tsx" */),
  "component---src-pages-solution-optimized-warehouse-management-systems-tsx": () => import("./../../../src/pages/solution-optimized-warehouse-management-systems.tsx" /* webpackChunkName: "component---src-pages-solution-optimized-warehouse-management-systems-tsx" */),
  "component---src-pages-solution-transform-transportation-with-comprehensive-transportation-management-system-tsx": () => import("./../../../src/pages/solution-transform-transportation-with-comprehensive-transportation-management-system.tsx" /* webpackChunkName: "component---src-pages-solution-transform-transportation-with-comprehensive-transportation-management-system-tsx" */),
  "component---src-pages-solution-transform-your-order-management-system-tsx": () => import("./../../../src/pages/solution-transform-your-order-management-system.tsx" /* webpackChunkName: "component---src-pages-solution-transform-your-order-management-system-tsx" */),
  "component---src-pages-solution-transform-your-supply-chain-with-seamless-integration-tsx": () => import("./../../../src/pages/solution-transform-your-supply-chain-with-seamless-integration.tsx" /* webpackChunkName: "component---src-pages-solution-transform-your-supply-chain-with-seamless-integration-tsx" */),
  "component---src-pages-sso-solution-tsx": () => import("./../../../src/pages/sso-solution.tsx" /* webpackChunkName: "component---src-pages-sso-solution-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-transforming-logistics-and-inventory-management-tsx": () => import("./../../../src/pages/transforming-logistics-and-inventory-management.tsx" /* webpackChunkName: "component---src-pages-transforming-logistics-and-inventory-management-tsx" */),
  "component---src-pages-travel-api-integration-solutions-tsx": () => import("./../../../src/pages/travel-api-integration-solutions.tsx" /* webpackChunkName: "component---src-pages-travel-api-integration-solutions-tsx" */),
  "component---src-pages-travel-management-software-tsx": () => import("./../../../src/pages/travel-management-software.tsx" /* webpackChunkName: "component---src-pages-travel-management-software-tsx" */),
  "component---src-pages-ui-ux-design-solutions-tsx": () => import("./../../../src/pages/ui-ux-design-solutions.tsx" /* webpackChunkName: "component---src-pages-ui-ux-design-solutions-tsx" */),
  "component---src-pages-unified-communication-solutions-system-integration-services-tsx": () => import("./../../../src/pages/unified-communication-solutions-system-integration-services.tsx" /* webpackChunkName: "component---src-pages-unified-communication-solutions-system-integration-services-tsx" */),
  "component---src-pages-vehicle-distribution-systems-tsx": () => import("./../../../src/pages/vehicle-distribution-systems.tsx" /* webpackChunkName: "component---src-pages-vehicle-distribution-systems-tsx" */),
  "component---src-templates-allcasestudy-tsx": () => import("./../../../src/templates/allcasestudy.tsx" /* webpackChunkName: "component---src-templates-allcasestudy-tsx" */),
  "component---src-templates-allwhitepapers-tsx": () => import("./../../../src/templates/allwhitepapers.tsx" /* webpackChunkName: "component---src-templates-allwhitepapers-tsx" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-casestudy-tsx": () => import("./../../../src/templates/casestudy.tsx" /* webpackChunkName: "component---src-templates-casestudy-tsx" */),
  "component---src-templates-jobrolepage-tsx": () => import("./../../../src/templates/jobrolepage.tsx" /* webpackChunkName: "component---src-templates-jobrolepage-tsx" */)
}

